import React from 'react';

const IssuesPage = () => {
  return (
    <div>
     issues
    </div>
  );
};

export default IssuesPage;