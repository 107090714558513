import React, { useState } from 'react';
import { Button, Grid, Paper, TextField } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import useStyles from "./DecisionsPage.styles";
import { useMutation, useQuery } from "react-query";
import DecisionsAPI from "../../services/decisions.service"
import Snackbar from "../../components/ui/Snackbar";


const columns = [
  { field: 'shortId', headerName: 'Id', width: 90 },
  { field: 'description', headerName: 'Description', width: 250 },
  { field: 'tags', headerName: 'Tags', width: 140 },
  { field: 'stakeholders', headerName: 'Stakeholders', width: 180 },
];

const DecisionsPage = () => {
  const classes = useStyles()
  const [decision, setDecision] = useState<string>('')
  const [page, setPage] = useState<number>(0)

  const limit = 20;

  const mutation = useMutation(DecisionsAPI.postDecision);
  const { mutate, isSuccess: postSuccess, isError: postError } = mutation;

  const { isError: getError, data } = useQuery(['decisions', page],
    async () => await DecisionsAPI.getDecisions(page, limit))

  const onSubmit = async () => {
    await mutate(decision);
  }

  return (
    <>
      <Paper elevation={3} className={classes.inputPaper}>
        <Grid container alignItems={"center"} justify={"center"} className={classes.wrapper}>
          <TextField label={"Decision..."} size={"medium"} className={classes.input} value={decision}
                     onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDecision(event.target.value)} />
          <Button variant={"contained"} color={"primary"} disableElevation size={"large"}
                  onClick={onSubmit}>Save</Button>
        </Grid>
      </Paper>
      <Paper elevation={3} className={classes.listPaper}>
        {data ? <DataGrid rows={data.decisions}
                          columns={columns}
                          checkboxSelection pagination
                          page={page}
                          pageSize={20}
                          rowCount={data.totalResults}
                          paginationMode="server"
                          onPageChange={(params) => {
                            setPage(params.page);
                          }} /> : null}
      </Paper>
      {postSuccess && <Snackbar isOpen={true} type={'success'} message={"Decision was saved successfully"} />}
      {(postError || getError) && <Snackbar isOpen={true} type={'error'} message={"An error occurred"} />}
    </>
  );
};

export default DecisionsPage;