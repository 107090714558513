import React, { useCallback, useState } from 'react'
import MuiSnackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert'

const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />

interface SnackbarProps {
  isOpen: boolean
  type: Color
  message: string
}

const Snackbar: React.FC<SnackbarProps> = (props: SnackbarProps) => {
  const { isOpen, type, message } = props

  const [open, setOpen] = useState<boolean>(isOpen)

  const handleCloseSnackbar = useCallback(() => setOpen(false), [])

  return (
    <MuiSnackbar open={open} autoHideDuration={5000} onClose={handleCloseSnackbar}>
      <Alert onClose={handleCloseSnackbar} severity={type as Color}>
        {message}
      </Alert>
    </MuiSnackbar>
  )
}

export default Snackbar
