interface IConfig {
  BACKEND_URL: string
}

const config: IConfig = {
  BACKEND_URL: `https://decisionview.azurewebsites.net`,
}

// @ts-ignore
export default config
