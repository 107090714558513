import config from "../config";
import HttpService from "./http.service";

class DecisionsAPI extends HttpService {
  postDecision = (description: string) => {
    return this.post('decisions', { decision: { description } });
  };

  getDecisions = async (page: number, pageSize: number) => {
    return await this.get(`decisions?page=${page}&pageSize=${pageSize}`)
  }
}

export default new DecisionsAPI({
  apiURL: config.BACKEND_URL,
});
