import { createMuiTheme } from '@material-ui/core/styles'

const montserrat = require('typeface-montserrat')

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0bf',
      contrastText: "#fff"
    },
    secondary: {
      main: '#f5f6f8',
    },
  },
  typography: {
    h1: {
      fontSize: '24px',
      lineHeight: '34px',
    },
    h2: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    h4: {
      fontSize: '14px',
      lineHeight: '24px',
      color: '#383541',
      fontWeight: 700,
    },
    body1: {
      fontSize: '14px',
      lineHeight: '24px',
      color: '#383541',
      fontWeight: 500,
    },
    body2: {
      fontSize: '14px',
      lineHeight: '24px',
      color: '#383541',
      fontWeight: 600,
    },
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontWeightLight: 'bold',
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        "#root": {
          height: '100%',
          minHeight: '100vh',
          position: 'relative',
          width: '100%'
        },
        "::-webkit-scrollbar": {
          width: "5px!important",
          height: "5px!important"
        },
        "::-webkit-scrollbar-track": {
          borderRadius: "10px !important"
        },
        "::-webkit-scrollbar-thumb": {
          background: "lightgray!important",
          borderRadius: "10px!important",
        },
        '@font-face': [
          {
            fontFamily: montserrat,
            fontStyle: 'normal',
          },
        ],
      },
    },
    MuiButton: {
      root: {
        fontSize: '14px',
        fontWeight: 600,
        textTransform: 'uppercase',
        lineHeight: '24px',
        padding: '10px 16px',
        borderRadius: '8px',
        color: '#FFF',
      },
      containedPrimary: {
        padding: '10px 16px',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
      }
    },

  },
  shadows: [
    "none", "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    " rgba(149, 157, 165, 0.2) 0px 8px 24px"
    , "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)"
    , "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
    , "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px"
    , "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)"
    , "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"
    , "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)"
    , "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)"
    , "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)"
    , "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)"
    , "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)"
    , "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)"
    , "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)"
    , "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)"
    , "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)"
    , "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)"
    , "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)"
    , "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)"
    , "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)"
    , "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)"
    , "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)"
    , "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)"
  ]

})

export default theme
