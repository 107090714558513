import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  input: {
    width: '35vw',
    marginRight: 10
  },
  inputPaper: {
    width: '70%',
    height: 160,
    borderRadius: 10,
    margin: '10px auto'
  },
  listPaper: {
    width: '70%',
    height: 800,
    borderRadius: 10,
    margin: '20px auto'
  },
  wrapper: {
    height: '100%'
  }
})

export default useStyles