import axios, { AxiosError, AxiosResponse, Method } from 'axios'
import config from '../config'

export interface IRequestParams {
  url: string
  method?: Method
  headers?: any
  data?: any
  auth?: boolean
  tokenFromEmail?: string
}

export interface IHttpConfig {
  apiURL?: string
  headers?: any
}

class HttpService {
  client = axios.create()

  config: IHttpConfig = {
    apiURL: config.BACKEND_URL,
    headers: {
      Accept: 'application/json, multipart/form-data',
      'Access-Control-Allow-Origin': '*',
    },
  }

  constructor(params: IHttpConfig) {
    this.config = { ...this.config, ...params }
  }

  getHeaders = (headers: any, auth?: boolean) => {
    const token = localStorage.getItem('token');
    const headersObj =
      token && auth ? { ...headers, Authorization: `Bearer ${token}` } : { ...headers };

    return Object.assign({}, this.config.headers, headersObj);
  };


  getURL = (url: string) => {
    return `${this.config.apiURL}/${url}`
  }

  request = (params: IRequestParams) => {
    const headers = this.getHeaders(params.headers, params.auth)
    const url = this.getURL(params.url)

    const requestParams = {
      url,
      headers,
      method: params.method,
      data: params.data,
    }
    console.log(requestParams)

    return axios.request(requestParams).then((response: AxiosResponse<any>) => response.data)
    .catch((err: AxiosError) => {throw err})
  }

  post = (url: string, data?: any, auth: boolean = false) => {
    return this.request({ url, data, auth, method: 'post' })
  }

  put = (url: string, data?: any, auth: boolean = true) => {
    return this.request({ url, data, auth, method: 'put' })
  }

  patch = (url: string, data?: any, tokenFromEmail?: string, auth: boolean = true) => {
    return this.request({ url, data, auth, method: 'patch', tokenFromEmail })
  }

  get = (url: string, data?: any, auth: boolean = false) => {
    return this.request({ url, data, auth, method: 'get' })
  }

  delete = (url: string, data?: any, auth: boolean = true) => {
    return this.request({ url, data, auth, method: 'delete' })
  }
}

export default HttpService
