import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  
  ListItemText,
  Toolbar,
  Typography
} from "@material-ui/core";
import useStyles from "./SidebarLayout.styles"; 
import clsx from "clsx"
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SIDEBAR from "../sidebar.json";
import { useHistory } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';

interface SidebarLayoutProps {
  children: any
}

const SidebarLayout: React.FC<SidebarLayoutProps> = ({ children }: SidebarLayoutProps) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const [openSubMenu, setOpenSubmenu] = useState<string | null>(null);
    const history = useHistory();

    useEffect(() => {
      window.localStorage.setItem('menu', JSON.stringify(SIDEBAR));
    }, [])

    const handleDrawerOpen = () => {
      setOpen(true);
    };

    const handleDrawerClose = () => {
      setOpen(false);
    }

    const handleSubMenuToggle = (subMenu: string) => {
      if (openSubMenu === subMenu) setOpenSubmenu(null)
      if (!openSubMenu || openSubMenu !== subMenu) setOpenSubmenu(subMenu)
    }

    const onAddFolder = (name: string) => {
      const menu = JSON.parse(window.localStorage.getItem('menu') as string);
      menu.menuItems.push({
        "type": "folder",
        "name": name,
        "files": [],
      })
      window.localStorage.setItem('menu', JSON.stringify(menu));
    }

    const onAddFile = (title: string, folderIndex: number) => {
      const menu = JSON.parse(window.localStorage.getItem('menu') as string);
      menu.menuItems[folderIndex].files.push({
        "title": title,
        "key": `/applications/${title}`
      })
      window.localStorage.setItem('menu', JSON.stringify(menu));
    }

    const renderSubMenu = (item: any, index: number) => (
      <>
        <ListItem button key={index} onClick={() => handleSubMenuToggle(item.name)}>
          <ListItemText primary={item.name} classes={{ primary: classes.menuText }} />
          {openSubMenu === item.name ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSubMenu === item.name} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button>
              <ListItemText primary="Starred" classes={{ primary: classes.subMenuText }} />
            </ListItem>
          </List>
        </Collapse>
      </>
    );


    return <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Decision View
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton><NoteAddIcon className={classes.menuIcon} /></IconButton>
          <IconButton><AddToPhotosIcon className={classes.menuIcon} /></IconButton>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon className={classes.menuIcon} />
          </IconButton>
        </div>
        <List component="nav">
          {SIDEBAR.menuItems.map((menuItem: any, index) => (
            menuItem.type === 'folder'
              ? renderSubMenu(menuItem, index)
              : <ListItem button key={index} onClick={() => history.push(`/${menuItem.key}`)}>
                <ListItemText primary={menuItem.title} classes={{ primary: classes.menuText }} />
              </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  }

export default SidebarLayout;