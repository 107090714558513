import React from 'react';

const SettingsPage = () => {
  return (
    <div>
      settings
    </div>
  );
};

export default SettingsPage;