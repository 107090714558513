import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SidebarLayout from "../components/layout/SidebarLayout";
import links from "../framework/links";
import RisksPage from "../pages/RisksPage";
import IssuesPage from "../pages/IssuesPage";
import DecisionsPage from "../pages/DecisionsPage";
import SettingsPage from "../pages/SettingsPage";
import IntegrationPage from "../pages/IntegrationPage";
import LoginPage from "../pages/LoginPage";
import { PrivateRoute } from "../components/PrivateRoute";


const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={links.decisions} />

      {/*<Route path={links.login} component={LoginPage} exact />*/}

      <SidebarLayout>
        <Switch>
          <Route path={links.risks} component={RisksPage} exact />
          <Route path={links.issues} component={IssuesPage} exact />
          <Route path={links.decisions} component={DecisionsPage} exact />
          <Route path={links.settings} component={SettingsPage} exact />
          <Route path={links.integration} component={IntegrationPage} exact />
        </Switch>
      </SidebarLayout>
    </Switch>
  );
};

export default Routes;